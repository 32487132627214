<template>
    <VeeForm
        ref="baseForm"
        v-slot="{ handleSubmit }"
        as="div"
        @invalid-submit="onInvalidSubmit"
    >
        <form novalidate @submit="handleSubmit($event, safeSubmit)">
            <slot :isLoading="isLoading"></slot>
            <div v-if="submitButton" :class="buttonContainerClass">
                <LoadingButton
                    type="submit"
                    :isLoading="isLoading"
                    :isLoaded="isLoaded"
                    :class="buttonClass"
                    :disabled="isDisabled"
                >
                    {{ submitButton.text }}
                </LoadingButton>
            </div>
        </form>
    </VeeForm>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Form as VeeForm } from 'vee-validate';

import { useActions } from '~coreModules/core/js/composables/vuexHelpers';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';
import { CLIENT_ERROR } from '~coreModules/core/js/global-event-constants';

import LoadingButton from '~coreModules/core/components/ui/buttons/LoadingButton.vue';

const props = defineProps({
    onSubmit: {
        type: Function,
        required: true,
    },
    submitButton: {
        type: Object,
        default: null,
        validator: obj => !!obj.text,
    },
    shouldSetMinLoadTime: {
        type: Boolean,
        default: false,
    },
    shouldAllowResubmission: {
        type: Boolean,
        default: false,
    },
    isLoaded: {
        type: Boolean,
        default: false,
    },
});

const { trackGlobalEvent } = useActions({ trackGlobalEvent: GLOBAL_EVENT });

const baseForm = ref(null);
const hasBeenSubmitted = ref(false);
const isLoading = ref(false);
const buttonClass = ref(props.submitButton?.class || '');
const buttonContainerClass = ref(props.submitButton?.buttonContainerClass || '');

const SUBMIT_DEBOUNCE_TIME = 500;

const isDisabled = computed(() => props.submitButton?.disabled || false);

/* eslint-disable consistent-return, no-use-before-define */
async function triggerFormSubmit() {
    const { valid, errors } = await baseForm.value.validate();

    if (errors) handleClientError(errors);

    if (!valid) {
        return Promise.reject();
    }

    await safeSubmit?.();
}

function safeSubmit(...opts) {
    if (!hasBeenSubmitted.value) {
        isLoading.value = true;
        hasBeenSubmitted.value = true;

        return props.onSubmit?.(...opts)
            .catch((error) => {
                setTimeout(() => {
                    hasBeenSubmitted.value = false;
                }, SUBMIT_DEBOUNCE_TIME);
                return Promise.reject(error);
            })
            .finally(() => {
                if (props.shouldSetMinLoadTime) {
                    setTimeout(() => {
                        isLoading.value = false;
                    }, SUBMIT_DEBOUNCE_TIME);
                } else {
                    isLoading.value = false;
                }

                if (props.shouldAllowResubmission) {
                    setTimeout(() => {
                        hasBeenSubmitted.value = false;
                    }, SUBMIT_DEBOUNCE_TIME);
                }
            });
    }

    return Promise.resolve();
}

function reset() {
    baseForm.value.resetForm();
}

function getValidationErrors(errors = {}) {
    return Object.values(errors).flatMap(error => (Array.isArray(error) ? error : [error]));
}

function handleClientError(errors) {
    const validationErrors = getValidationErrors(errors);

    return validationErrors.length &&
        trackGlobalEvent({ type: CLIENT_ERROR, data: { validationErrors } });
}

function onInvalidSubmit(value) {
    if (value.errors) handleClientError(value.errors);
}

defineExpose({ reset, triggerFormSubmit });
</script>

<style lang="scss">

</style>
